import { ChartPie } from "@bhawanadevteam/react-core";
import { DataStatus } from "components";
import React, { useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import { useQuery } from "react-query";
import { DashboardApi } from "api";

export const TotalKendaraanChart = ({
  monthOptions,
  startMonth,
  endMonth,
  year,
}) => {
  const [selectedMonth, setSelectedMonth] = useState({
    label: "",
    value: startMonth,
  });

  // navbar month name
  const monthsInRange = getMonthsBetweenStartAndEnd(
    startMonth,
    endMonth,
    monthOptions
  );

  // function get month range
  function getMonthsBetweenStartAndEnd(
    startMonthValue,
    endMonthValue,
    monthOptions
  ) {
    const filteredMonths = monthOptions.filter(
      (val) => val.value >= startMonthValue && val.value <= endMonthValue
    );

    return filteredMonths;
  }

  // fetchApi
  const { data: dataTotalKendaraan, isFetching: loadingTotalKendaraan } =
    useQuery(["dataTotalKendaraan", year, startMonth, endMonth], () =>
      DashboardApi.get({
        modul: "ASM",
        part: "kendaraan",
        tahun: year,
        bulan_mulai: startMonth,
        bulan_selesai: endMonth,
      })
    );

  return (
    <Card className="border mt-3">
      {monthsInRange?.length ? (
        <Tab.Container
          key={monthsInRange.join("-")}
          defaultActiveKey={monthsInRange[0].value}
        >
          <Card.Header className='className="d-flex justify-content-between align-items-center'>
            <Nav variant="tabs">
              {monthsInRange?.map((month) => (
                <Nav.Item>
                  <Nav.Link
                    eventKey={month.value}
                    onClick={() =>
                      setSelectedMonth({
                        label: month.label,
                        value: month.value,
                      })
                    }
                  >
                    {month.label}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card.Header>

          <Card.Body>
            <Tab.Content>
              {loadingTotalKendaraan ? (
                <div>Memuat Data . . .</div>
              ) : (
                <>
                  <div className="text-center">
                    <b>Total Kendaraan Yang Dipakai Untuk Surat Jalan</b>
                  </div>
                  {dataTotalKendaraan?.data?.filter(
                    (val) =>
                      parseInt(val.bulan) === parseInt(selectedMonth.value)
                  ).length === 0 ? (
                    <DataStatus text="Tidak ada data" />
                  ) : (
                    <ChartPie
                      width={100}
                      data={{
                        labels:
                          dataTotalKendaraan?.data
                            ?.filter(
                              (val) =>
                                parseInt(val.bulan) ===
                                parseInt(selectedMonth.value)
                            )
                            .map(
                              (val) =>
                                val?.nama_item_aset ?? "Tidak Memerlukan Armada"
                            ) ?? [],
                        datasets: [
                          {
                            data:
                              dataTotalKendaraan?.data
                                ?.filter(
                                  (val) =>
                                    parseInt(val.bulan) ===
                                    parseInt(selectedMonth.value)
                                )
                                .map((val) => val?.total ?? 0) ?? [],
                            hoverOffset: 4,
                          },
                        ],
                      }}
                      options={{
                        plugins: {
                          legend: {
                            position: "right",
                          },
                        },
                      }}
                    />
                  )}
                </>
              )}
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
    </Card>
  );
};

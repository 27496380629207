import Services from '../../services';

class AuthApi {
  login(value) {
    return Services.post('/hrdu/auth/login', value);
  }

  notif(params) {
    return Services.get('/hrdu/notification/counter_notif', { params });
  }
}

export default new AuthApi();

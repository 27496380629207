import { range } from 'lodash';
import { dateConvert } from 'utilities2';
const date = dateConvert();
const yearNow = date.getYear(new Date());

export const generateYearOptions = range(2000, parseInt(yearNow + 1))
  .map((val) => ({
    label: val,
    value: val,
  }))
  .reverse();


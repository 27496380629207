import { useEffect } from 'react';

export const DashboardSia = ({ setNavbarTitle }) => {
  const { REACT_APP_IFRAME_SIA } = process.env;

  const iframeStyle = {
    border: 'none', // Optional: Remove border around the iframe
    width: '100%',
    height: '90vh', // Set the desired height
    overflow: 'auto', // Enable scrolling if content overflows
  };

  useEffect(() => setNavbarTitle('Dashboard SIA'), []);

  return (
    <div>
      <iframe style={iframeStyle} title="SIA" src={REACT_APP_IFRAME_SIA} />
    </div>
  );
};

import { DashboardApi } from 'api';
import { DataStatus, Select, Table } from 'components';
import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useQuery } from 'react-query';
import { TableNumber } from 'utilities';
import { dateConvert, rupiahConvert } from 'utilities2';

export const TablePurchaseOrder = ({ month, monthName, year }) => {
  const date = dateConvert();
  const rupiah = rupiahConvert();

  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
  });

  const { data, isFetching, isError } = useQuery(
    ['dashboard', 'PRO', 'page', month, year, paginationConfig],
    () =>
      DashboardApi.get({
        modul: 'PRO',
        part: 'page',
        bulan: month,
        tahun: year,
        page: paginationConfig.page,
        per_page: paginationConfig.dataLength,
      }),
  );

  return (
    <div className="p-3 bg-white border rounded shadow-sm mb-4 mt-2">
      <div className="m-2">
        <b>
          List Data Purchase Order Bulan {monthName} {year}
        </b>
      </div>

      <Table>
        <thead>
          <tr>
            <th
              style={{ fontSize: 12 }}
              className="p-1 text-center align-middle"
            >
              No.
            </th>
            <th
              style={{ fontSize: 12 }}
              className="p-1 text-center align-middle"
            >
              Tgl. Purchase Order
            </th>
            <th
              style={{ fontSize: 12 }}
              className="p-1 text-center align-middle"
            >
              No. Purchase Order
            </th>
            <th
              width={150}
              style={{ fontSize: 12 }}
              className="text-center align-middle"
            >
              Vendor
            </th>
            <th
              style={{ fontSize: 12 }}
              className="p-1 text-center align-middle"
            >
              Total Pembelian
            </th>
          </tr>
        </thead>
        <tbody>
          {isFetching || !data?.data?.length > 0 ? (
            <tr>
              <td colSpan={5}>
                <DataStatus
                  loading={isFetching}
                  text={
                    isFetching
                      ? 'Memuat data . . .'
                      : isError
                      ? 'Data gagal dimuat'
                      : 'Tidak ada data'
                  }
                />
              </td>
            </tr>
          ) : (
            data?.data?.map((val, index) => (
              <tr>
                <td
                  style={{ fontSize: 12 }}
                  className="p-1 align-middle text-center"
                >
                  {TableNumber(
                    paginationConfig?.page,
                    paginationConfig.dataLength,
                    index,
                  )}
                </td>
                <td style={{ fontSize: 12 }} className="p-1 align-middle">
                  {val?.tgl_purchase_order
                    ? date.getSlashDMY(new Date(val.tgl_purchase_order))
                    : '-'}
                </td>
                <td style={{ fontSize: 12 }} className="p-1 align-middle">
                  {val?.no_purchase_order}
                </td>
                <td style={{ fontSize: 12 }} className="p-1 align-middle">
                  {val?.nama_vendor}
                </td>
                <td style={{ fontSize: 12 }} className="p-1 align-middle">
                  {val?.total ? rupiah.getWithComa(val.total) : '-'}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      {!isFetching && !isError && data?.data?.length > 0 && (
        <Pagination
          size="sm"
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            data?.data_count < paginationConfig.dataLength
              ? data?.data_count
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={data?.data_count}
          currentPage={paginationConfig.page}
          totalPage={data?.total_page}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      )}
    </div>
  );
};

const Pagination = ({
  dataLength,
  onDataLengthChange,
  currentPage,
  totalPage,
  onPaginationChange,
  dataPage,
  dataCount,
  dataNumber,
  size = 'md',
}) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <small className="mb-2">
        Menampilkan {dataNumber} - {dataCount < dataPage ? dataCount : dataPage}{' '}
        dari {dataCount} data
      </small>

      <ReactPaginate
        pageCount={totalPage}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        containerClassName="pagination pagination-sm justify-content-center"
        pageLinkClassName="page-link"
        breakClassName="page-link"
        previousClassName="page-link"
        nextClassName="page-link"
        activeClassName="page-item active"
        disabledClassName="page-item disabled"
        previousLabel="&laquo;"
        nextLabel="&raquo;"
        onPageChange={onPaginationChange}
        initialPage={currentPage - 1}
        disableInitialCallback={true}
      />

      <small className="mt-1">Tampilkan :</small>

      <Select
        defaultValue={dataLength}
        style={{ width: '150px' }}
        onChange={onDataLengthChange}
      >
        <option value="10">10 Data</option>
        <option value="20">20 Data</option>
        <option value="50">50 Data</option>
        <option value="100">100 Data</option>
      </Select>
    </div>
  );
};

import DashboardApi from 'api/Dashboard';
import { DatePicker } from 'components';
import { Button, Select } from 'components2';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { dateConvert } from 'utilities2';
import {
  InfoBarangTotalProduksi,
  InfoItem,
  TableItemTerproduksi,
  TableProsesProduksi,
} from './components';
import { monthOptions } from './constants';
import {
  calculatePercentage,
  generateMaxDate,
  generateYearOptions,
  getMonthNameList,
} from './helpers';

const date = dateConvert();
const monthNow = date.getMonth(new Date());
const defaultEndDate = new Date(
  new Date().getFullYear(),
  new Date().getMonth() + 1,
  new Date().getDate(),
);

export const DashboardProduksi = ({ setNavbarTitle }) => {
  const [filter, setFilter] = useState({
    startMonth: monthNow,
    endMonth: date.getMonth(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        new Date().getDate(),
      ),
    ),
    year: date.getYear(new Date()),
    convertMonth: {
      startDate: '',
      endDate: '',
    },
  });

  const getTotalProduksi = useQuery(
    [
      'dashboard',
      'prd',
      'total',
      filter.year,
      filter.startMonth,
      filter.endMonth,
    ],
    () =>
      DashboardApi.get({
        modul: 'PRD',
        part: 'total',
        bulan: filter?.startMonth,
        bulan_sampai: filter?.endMonth,
        tahun: filter?.year,
      }),
  );

  useEffect(() => {
    setNavbarTitle('Dashboard Produksi');

    if (filter.startMonth && filter.endMonth) {
      const monthName = getMonthNameList(filter.startMonth, filter.endMonth);

      setFilter((prev) => ({
        ...prev,
        convertMonth: {
          startDate: monthName[0],
          endDate: monthName[1],
        },
      }));
    }
  }, [setNavbarTitle, filter.startMonth, filter.endMonth]);

  return (
    <>
      {/* Filter Section */}
      <Formik
        initialValues={{
          startMonth: new Date(),
          endMonth: defaultEndDate,
          year: filter.year,
        }}
        onSubmit={(val) => {
          const startMonth = date.getMonth(new Date(val.startMonth));
          const endMonth = date.getMonth(new Date(val.endMonth));
          const monthName = getMonthNameList(startMonth, endMonth);

          setFilter({
            startMonth,
            endMonth,
            year: val.year,
            convertMonth: {
              startDate: monthName[0],
              endDate: monthName[1],
            },
          });
        }}
      >
        {({ values, setValues, handleSubmit }) => (
          <div className="d-flex justify-content-center justify-content-lg-end">
            <div className="d-flex flex-column flex-md-row">
              {/* Bulan */}
              <div className="d-flex align-items-center mr-2">
                <div style={{ width: 150 }}>
                  <DatePicker
                    selectsRange
                    dateFormat="MMMM"
                    showMonthYearPicker
                    selected={values.startMonth}
                    startDate={values.startMonth}
                    maxDate={values.startMonth ? generateMaxDate(values) : null}
                    endDate={values.endMonth}
                    onChange={([startMonth, endMonth]) =>
                      setValues({
                        ...values,
                        startMonth: startMonth,
                        endMonth: endMonth,
                      })
                    }
                  />
                </div>
              </div>

              {/* Tahun */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <span className="mr-2">Tahun</span>
                <div style={{ width: 150 }}>
                  <Select
                    noMargin
                    options={generateYearOptions}
                    defaultValue={generateYearOptions.find(
                      (val) => val.value === values.year,
                    )}
                    onChange={(val) =>
                      setValues((prev) => ({ ...prev, year: val.value }))
                    }
                  />
                </div>
              </div>

              {/* Button */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <Button
                  disabled={!values.startMonth || !values.endMonth}
                  text="Filter"
                  className="px-4"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>

      {/* Info Section */}
      <Row className="mt-3">
        {/* Info Total Produksi */}
        <Col className="mb-3">
          <InfoItem
            type="default"
            loading={getTotalProduksi.isFetching}
            title="Total Produksi"
            percent={calculatePercentage(
              getTotalProduksi.data?.data?.total_produksi,
              getTotalProduksi.data?.data?.total_produksi,
            )}
            value={
              getTotalProduksi.data?.data?.total_produksi
                ? parseInt(getTotalProduksi.data?.data?.total_produksi)
                : '0'
            }
            data={[parseInt(getTotalProduksi.data?.data?.total_produksi) ?? 0]}
            chartBgColor={['#0073b9', '#00abda']}
          />
        </Col>

        {/* Info Produksi Open */}
        <Col className="mb-3">
          <InfoItem
            loading={getTotalProduksi.isFetching}
            title="Produksi Open"
            percent={calculatePercentage(
              getTotalProduksi.data?.data?.total_produksi,
              getTotalProduksi.data?.data?.total_produksi_open,
            )}
            value={
              getTotalProduksi.data?.data?.total_produksi_open
                ? parseInt(getTotalProduksi.data?.data?.total_produksi_open)
                : '0'
            }
            data={[
              parseInt(getTotalProduksi.data?.data?.total_produksi) -
                parseInt(getTotalProduksi.data?.data?.total_produksi_open),
              parseInt(getTotalProduksi.data?.data?.total_produksi_open) ?? 0,
            ]}
            chartBgColor={['#00abda', '#0073b9']}
          />
        </Col>

        {/* Info Produksi Close */}
        <Col className="mb-3">
          <InfoItem
            loading={getTotalProduksi.isFetching}
            title="Produksi Closed"
            percent={calculatePercentage(
              getTotalProduksi.data?.data?.total_produksi,
              getTotalProduksi.data?.data?.total_produksi_close,
            )}
            value={
              getTotalProduksi.data?.data?.total_produksi_close
                ? parseInt(getTotalProduksi.data?.data?.total_produksi_close)
                : '0'
            }
            data={[
              parseInt(getTotalProduksi.data?.data?.total_produksi) -
                parseInt(getTotalProduksi.data?.data?.total_produksi_open),
              parseInt(getTotalProduksi.data?.data?.total_produksi_open) ?? 0,
            ]}
            chartBgColor={['#0073b9', '#00abda']}
          />
        </Col>
      </Row>

      {/* Chart Section */}
      <Row>
        <Col md={6}>
          {/* Table Section */}
          <TableProsesProduksi
            startMonth={filter.startMonth}
            endMonth={filter.endMonth}
            year={filter.year}
            convertMonth={filter.convertMonth}
          />
        </Col>
        <Col md={6}>
          <Col className="my-2">
            <InfoBarangTotalProduksi
              monthOptions={monthOptions}
              startMonth={filter.startMonth}
              endMonth={filter.endMonth}
              year={filter.year}
            />
          </Col>
          <Col className="my-2">
            <TableItemTerproduksi
              startMonth={filter.startMonth}
              endMonth={filter.endMonth}
              convertMonth={filter.convertMonth}
              year={filter.year}
            />
          </Col>
        </Col>
      </Row>
    </>
  );
};

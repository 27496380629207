import Logo from 'assets/image/LogoAJB.png';
import MenuConfig from './MenuConfig';
import RoutesConfig from './RoutesConfig';

export default {
  LOGO: Logo, //Logo
  MODUL: 'Dashboard',
  MENU: MenuConfig, //Konfigurasi menu pada sidebar
  ROUTES: RoutesConfig, //Konfigurasi routing
};

import {
  DashboardInventory,
  DashboardMarketing,
  DashboardPenjualan,
  DashboardProcurement,
  DashboardProduksi,
  DashboardSia,
  DashboardAsm,
} from "pages";

/* 
  MENU REQUIREMENTS
    > EXACT = OPTIONAL (TRUE/FALSE)
    > PATH  = REQUIRED
    > PAGE  = REQUIRED
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
*/

export default [
  {
    exact: true,
    path: "/produksi",
    page: DashboardProduksi,
    hak: ["SUPA", "DAS_PRD"],
  },
  {
    exact: true,
    path: "/marketing",
    page: DashboardMarketing,
    hak: ["SUPA", "DAS_MKT"],
  },
  {
    exact: true,
    path: "/inventory",
    page: DashboardInventory,
    hak: ["SUPA", "DAS_INV"],
  },
  {
    exact: true,
    path: "/procurement",
    page: DashboardProcurement,
    hak: ["SUPA", "DAS_PRO"],
  },
  {
    exact: true,
    path: "/penjualan",
    page: DashboardPenjualan,
    hak: ["SUPA", "DAS_SLS"],
  },
  {
    exact: true,
    path: "/sia",
    page: DashboardSia,
    hak: ["SUPA", "DAS_SIA"],
  },
  {
    exact: true,
    path: "/aset",
    page: DashboardAsm,
    hak: ["SUPA", "DAS_ASM"],
  },
];

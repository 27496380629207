import { monthOptions as months } from "../constants";

export const generateMonthInRange = (startMonth, endMonth) => {
  const awal = new Date(startMonth).getMonth() + 1;
  const akhir = new Date(endMonth).getMonth() + 1;

  const monthOptions = months.filter(
    ({ value }) => value <= akhir && value >= awal,
  );

  return monthOptions;
};
import Axios from 'axios';

const { REACT_APP_API_BASE_URL } = process.env;

const Services = Axios.create({
  baseURL: REACT_APP_API_BASE_URL,
});

Services.interceptors.request.use((config) => {
  const token = 'Bearer ' + sessionStorage.getItem('token');

  config.headers = {
    Authorization: token,
    'Content-Type': 'application/json',
  };

  return config;
});

export default Services;

import { useEffect, useState } from 'react';
import { Card, Col, Row, Tab, Nav, Tabs } from 'react-bootstrap';
import { HiOutlineUser } from 'react-icons/hi';
import { Button, ChartLine, Select } from 'components2';
import { Formik } from 'formik';
import { useQuery } from 'react-query';

import { DatePicker, DataStatus } from 'components';
import { dateConvert, rupiahConvert } from 'utilities2';
import { DashboardApi } from 'api';
import { monthOptions } from './constants';
import { generateYearOptions, generateMonthInRange } from './helpers';
import { InfoRankItem, InfoItem, ChartBarPerMonth } from './components';

const date = dateConvert();
const rupiah = rupiahConvert();

export const DashboardPenjualan = ({ setNavbarTitle }) => {
  const TODAY = new Date();

  const PAST_MONTH = new Date(
    TODAY.getFullYear(),
    TODAY.getMonth() - 1,
    TODAY.getDate(),
  );

  const [filter, setFilter] = useState({
    startMonth: PAST_MONTH,
    endMonth: TODAY,
    year: date.getYear(TODAY),
    monthOptions: generateMonthInRange(PAST_MONTH, TODAY),
  });

  const dateSelected = {
    tahun: filter.year,
    bulan_awal: parseInt(date.getMonth(filter.startMonth)),
    bulan_akhir: parseInt(date.getMonth(filter.endMonth)),
  };

  const [selectedMonth, setSelectedMonth] = useState({
    label: date.getDetailMonth(filter.startMonth),
    value: dateSelected.bulan_awal,
  });

  const getTotalCustomer = useQuery(
    ['dashboard', 'SLS', 'total_customer'],
    () =>
      DashboardApi.get({
        modul: 'SLS',
        part: 'total_customer',
        bulan: filter?.startMonth,
        bulan_sampai: filter?.endMonth,
        tahun: filter?.year,
      }),
  );

  const getRankingCustomer = useQuery(
    ['dashboard', 'SLS', 'peringkat_customer', dateSelected, filter],
    () =>
      DashboardApi.get({
        modul: 'SLS',
        part: 'peringkat_customer',
        bulan: dateSelected.bulan_awal,
        bulan_sampai: dateSelected.bulan_akhir,
        tahun: dateSelected.tahun,
      }),
  );

  const getTotalSummary = useQuery(
    ['dashboard', 'SLS', 'summary', dateSelected, filter],
    () =>
      DashboardApi.get({
        modul: 'SLS',
        part: 'summary',
        bulan: dateSelected.bulan_awal,
        bulan_sampai: dateSelected.bulan_akhir,
        tahun: dateSelected.tahun,
      }),
  );

  const getTotalPembelianPerbulan = useQuery(
    ['dashboard', 'SLS', 'total_pembelian_perbulan', dateSelected, filter],
    () =>
      DashboardApi.get({
        modul: 'SLS',
        part: 'total_pembelian_perbulan',
        tahun: dateSelected?.tahun,
      }),
  );

  useEffect(() => {
    setNavbarTitle('Dashboard Penjualan');
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          startMonth: filter.startMonth,
          endMonth: filter.endMonth,
          year: filter.year,
        }}
        onSubmit={(val) => {
          setFilter({
            startMonth: val.startMonth,
            endMonth: val.endMonth,
            year: val.year,
            monthOptions: generateMonthInRange(val.startMonth, val.endMonth),
          });

          setSelectedMonth({
            label: date.getDetailMonth(val.startMonth),
            value: date.getMonth(val.startMonth),
          });
        }}
      >
        {({ values, setFieldValue, setValues, handleSubmit }) => (
          <div className="d-flex justify-content-center justify-content-lg-end">
            <div className="d-flex flex-column flex-md-row">
              <div
                className="d-flex align-items-center mr-2"
                style={{ marginBottom: 30 }}
              >
                <span className="mr-2">Bulan</span>
                <div style={{ width: 150 }}>
                  <DatePicker
                    selectsRange
                    showMonthYearPicker
                    dateFormat="MMMM"
                    startDate={
                      values.startMonth ? new Date(values.startMonth) : ''
                    }
                    endDate={values.endMonth ? new Date(values.endMonth) : ''}
                    onChange={(dates) => {
                      const [start, end] = dates;

                      setValues({
                        ...values,
                        startMonth: start,
                        endMonth: end,
                      });
                    }}
                  />
                </div>
              </div>

              {/* Tahun */}
              <div
                className="d-flex align-items-center mr-2"
                style={{ marginBottom: 40 }}
              >
                <span className="mr-2">Tahun</span>
                <div style={{ width: 150 }}>
                  <Select
                    noMargin
                    options={generateYearOptions}
                    defaultValue={generateYearOptions.find(
                      (val) => val.value.toString() === values.year.toString(),
                    )}
                    onChange={(val) => {
                      setFieldValue('year', val.value);
                    }}
                  />
                </div>
              </div>

              {/* Button */}
              <div style={{ marginTop: 5 }}>
                <Button
                  disabled={!values.startMonth || !values.endMonth}
                  text="Filter"
                  className="px-4"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>

      {/* Info Section */}
      <Row className="">
        <Col className="mb-3">
          <InfoItem
            loading={getTotalCustomer?.isFetching}
            title="Total Customer"
            icon={<HiOutlineUser fontSize={50} />}
            value={getTotalCustomer?.data?.data?.total_customer ?? '-'}
          />
        </Col>

        {/* Info Total Sales Order */}
        <Col className="mb-3">
          <InfoItem
            loading={getTotalSummary?.isFetching}
            title="Total Sales Order"
            value={rupiah.getWithComa(
              getTotalSummary?.data?.data?.total_so ?? 0,
            )}
          />
        </Col>

        {/* Info Total SOSPK */}
        <Col className="mb-3">
          <InfoItem
            loading={getTotalSummary?.isFetching}
            title="Total Sales Order SPK"
            value={rupiah.getWithComa(
              getTotalSummary?.data?.data?.total_sospk ?? 0,
            )}
          />
        </Col>

        {/* Info Total Penjualan */}
        <Col className="mb-3">
          <InfoItem
            loading={getTotalSummary?.isFetching}
            title="Total Penjualan"
            value={rupiah.getWithComa(
              getTotalSummary?.data?.data?.total_penjualan ?? 0,
            )}
          />
        </Col>
      </Row>

      {/* Chart Section */}
      <Row>
        <Col md={6} className="my-3">
          <InfoRankItem
            loading={getRankingCustomer?.isFetching}
            startMonth={filter.startMonth}
            endMonth={filter.endMonth}
            year={filter.year}
            data={getRankingCustomer?.data?.data ?? []}
          />

          <div
            className="p-3 bg-white border rounded shadow-sm"
            style={{ height: '100%' }}
          >
            <div className="text-center">
              <b>Total Pembelian Per Bulan Tahun {dateSelected.tahun}</b>
            </div>
            {getTotalPembelianPerbulan.isFetching ? (
              <div className="d-flex align-items-center justify-content-center my-5">
                Memuat data . . .
              </div>
            ) : (
              <>
                <ChartLine
                  showLegend={false}
                  data={{
                    labels: monthOptions.map((val) => val.label),
                    datasets: [
                      {
                        data: monthOptions.map((val) => {
                          const findMonth =
                            getTotalPembelianPerbulan?.data?.data?.find(
                              (el) =>
                                el?.bulan_pembelian?.toString() ===
                                val.value.toString(),
                            );

                          return findMonth?.total
                            ? parseFloat(findMonth.total)
                            : 0;
                        }),
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (val) => rupiah.getWithComa(val.raw),
                        },
                      },
                    },
                    scales: {
                      y: {
                        ticks: {
                          callback: (val) => rupiah.getWithComa(val),
                        },
                      },
                    },
                  }}
                />
              </>
            )}
          </div>
        </Col>

        <Col md={6} className="my-3">
          <Card className="border">
            {filter?.monthOptions?.length ? (
              <Tab.Container
                key={filter?.monthOptions.join('-')}
                defaultActiveKey={filter?.monthOptions[0].value}
              >
                <Card.Header className='className="d-flex justify-content-between align-items-center'>
                  <Nav variant="tabs">
                    {filter.monthOptions?.map((month) => (
                      <Nav.Item key={month.value}>
                        <Nav.Link
                          eventKey={month.value}
                          onClick={() => {
                            setSelectedMonth({
                              label: month.label,
                              value: month.value,
                            });
                          }}
                        >
                          {month.label}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Card.Header>

                <Card.Body>
                  <Tab.Content>
                    <ChartBarPerMonth
                      filter={filter}
                      selectedMonth={selectedMonth}
                      startMonth={filter.startMonth}
                      endMonth={filter.endMonth}
                      year={filter.year}
                    />
                  </Tab.Content>
                </Card.Body>
              </Tab.Container>
            ) : (
              <DataStatus text="Tidak ada data" />
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

import { DashboardApi } from 'api';
import { DataStatus, Table } from 'components';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { DecimalConvert, TableNumber } from 'utilities';
import { Pagination } from './Pagination';

export const TableItemTerproduksi = ({
  startMonth,
  endMonth,
  convertMonth,
  year,
}) => {
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
  });

  const getItemTerproduksi = useQuery(
    [
      'dashboard',
      'prd',
      'item_terproduksi',
      year,
      startMonth,
      endMonth,
      paginationConfig.page,
      paginationConfig.dataLength,
    ],
    () =>
      DashboardApi.get({
        modul: 'PRD',
        part: 'item_terproduksi',
        tahun: year,
        bulan_awal: startMonth,
        bulan_akhir: endMonth,
        page: paginationConfig.page,
        per_page: paginationConfig.dataLength,
      }),
  );

  return (
    <div className="p-3 bg-white border rounded shadow-sm mb-4 mt-2">
      <div className="m-2">
        <b>
          List Data Item Yang Ter-Produksi Bulan{' '}
          {`${convertMonth.startDate} - ${convertMonth.endDate}`}
          {` ${year}`}
        </b>
      </div>
      <Table>
        <thead>
          <tr>
            <th
              style={{ fontSize: 12 }}
              className="p-1 text-center align-middle"
            >
              No.
            </th>
            <th
              style={{ fontSize: 12 }}
              className="p-1 text-center align-middle"
            >
              Item Produksi
            </th>
            <th
              style={{ fontSize: 12 }}
              className="p-1 text-center align-middle"
            >
              Jenis Item Produksi
            </th>
            <th
              width={150}
              style={{ fontSize: 12 }}
              className="text-center align-middle"
            >
              Qty. Produksi
            </th>
          </tr>
        </thead>
        <tbody>
          {getItemTerproduksi.isFetching ||
          !getItemTerproduksi?.data?.data?.data?.length > 0 ? (
            <tr>
              <td colSpan={5}>
                <DataStatus
                  loading={getItemTerproduksi.isFetching}
                  text={
                    getItemTerproduksi.isFetching
                      ? 'Memuat data . . .'
                      : getItemTerproduksi.isError
                      ? 'Data gagal dimuat'
                      : 'Tidak ada data'
                  }
                />
              </td>
            </tr>
          ) : (
            getItemTerproduksi.data?.data?.data?.map((val, index) => (
              <tr>
                <td
                  style={{ fontSize: 12 }}
                  className="p-1 align-middle text-center"
                >
                  {TableNumber(
                    paginationConfig?.page,
                    paginationConfig.dataLength,
                    index,
                  )}
                </td>
                <td style={{ fontSize: 12 }} className="p-1 align-middle">
                  {val?.nama_item}
                </td>
                <td style={{ fontSize: 12 }} className="p-1 align-middle">
                  {val?.nama_jenis}
                </td>
                <td style={{ fontSize: 12 }} className="p-1 align-middle">
                  {`${DecimalConvert(val?.qty_produksi).getWithComa} pcs`}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      {getItemTerproduksi.isFetching ||
      getItemTerproduksi.data?.data?.data_count === 0 ? (
        ''
      ) : (
        <Pagination
          size="sm"
          dataLength={paginationConfig?.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            getItemTerproduksi.data?.data?.data_count <
            paginationConfig.dataLength
              ? getItemTerproduksi.data?.data?.data_count
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={getItemTerproduksi.data?.data?.data_count}
          currentPage={
            getItemTerproduksi.data?.data?.data_count <=
            paginationConfig.dataLength
              ? 1
              : paginationConfig.page
          }
          totalPage={getItemTerproduksi.data?.data?.total_page}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      )}
    </div>
  );
};

import { memo } from 'react';

export const InfoItem = memo(({ icon, title, value, loading }) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center bg-white rounded border shadow-sm px-4"
      style={{ height: '100%' }}
    >
      <div style={{ fontSize: 70 }}>{icon}</div>
      <div className="d-flex flex-column align-items-end justify-content-end">
        <div>{title}</div>
        {loading ? (
          <small>Memuat data . . .</small>
        ) : (
          <b style={{ fontSize: 28, fontWeight: 1200, lineHeight: 1.2 }}>
            {value}
          </b>
        )}
      </div>
    </div>
  );
});

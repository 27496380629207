import {memo} from 'react'

export const InfoItem = memo(({ icon, title, value, loading }) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center bg-white rounded border shadow-sm p-3"
      style={{ height: '100%' }}
    >
      {icon ? (
        icon
      ) : (
        <div
          className="bg-primary rounded px-2 my-2 mr-2"
          style={{ fontSize: 30 }}
        >
          Rp
        </div>
      )}

      <div className="d-flex flex-column align-items-end justify-content-end">
        <div
          className="text-nowrap mb-1"
          style={{ fontSize: 18, fontWeight: 400 }}
        >
          {title}
        </div>
        {loading ? (
          <small>Memuat data . . .</small>
        ) : (
          <b
            className="ml-2"
            style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1.2 }}
          >
            {value}
          </b>
        )}
      </div>
    </div>
  );
});
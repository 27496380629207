import { memo } from 'react';
import { ChartDoughnut } from 'components2';

export const InfoDoughnutChart = memo(
  ({ color, datasets, title, value }) => (
    <div className="position-relative">
      <div
        style={{
          position: 'absolute',
          top: 38,
          left: value?.toString()?.length > 2 ? 28 : 32,
          color: color,
        }}
      >
        <b style={{ fontSize: 14 }}>{value}%</b>
      </div>
      <ChartDoughnut
        height={90}
        width={90}
        data={{
          labels: [],
          datasets: datasets,
        }}
      />
      <div className="text-center font-weight-bold" style={{ color: color }}>
        {title}
      </div>
    </div>
  ),
  (prev, curr) => {
    return prev.value === curr.value;
  },
);
